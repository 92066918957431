<!-- 首页 -->
<template>
	<div class="container">
		<!-- banner栏-START  -->
		<div class="flex mb-30">
			<div class="classify-container" @mouseleave="mouseleave">
				<div class="classify-list">
					<div v-for="(item,index) in classifyList" :key="item.id">
						<template v-if="item.categoryList.length == 0">
							<span @mouseenter="mouseenter(index)" @click="toGoodList(item.name, item.id)">{{item.name}}</span>
						</template>
						<template v-else>
							<span @mouseenter="mouseenter(index)">{{item.name}}</span>
						</template>
					</div>
				</div>
				<div v-show="show" class="classify-children">
					<template v-if="active !== ''">
						<div class="children-item" v-for="(item,index) in classifyList[active].categoryList" :key="index" @click="toGoodList(item.name, item.id)">
							<img :src="item.thumb" class="mb-10" />
							<span>{{item.name}}</span>
						</div>
					</template>
				</div>
			</div>
			<div class="banner-container">
				<el-carousel trigger="click">
					<el-carousel-item v-for="(item, index) in bannerList" :key="index">
						<img :src="item.thumb">
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="sidebar-container">
				<div class="sidebar-qrcode-box flex-column align-c">
					<img class="qrcode" src="../../assets/images/qr_code.jpg">
					<div class="qrcode-tips"></div>
				</div>

				<div class="merquee-container flex align-c">
					<!-- <div class="merquee-icon shrink-0"></div>
					<div class="flex-1 min-width-0"> -->
					<marquee :text="notice" />
					<!-- </div> -->
				</div>
				<ul class="meta-container color-666 flex justify-s-a">
					<router-link :to="{name: 'myOrder'}" tag="li" class="flex-column align-c point">
						<div class="order"></div>
						<p class="mt-10">我的订单</p>
					</router-link>
					<router-link :to="{name: 'myCoupon'}" tag="li" class="flex-column align-c point">
						<div class="coupon"></div>
						<p class="mt-10">我的优惠券</p>
					</router-link>
				</ul>
			</div>
		</div>
		<!-- banner栏-END -->

		<!-- 专区组件 -->
		<SpecialZone :type="3" ref="miaosha"/>
		<SpecialZone :type="13" ref="healthy"/>
		<SpecialZone :type="0" ref="recommend"/>
		
		<!-- 优惠券 -->
		<coupon/>
	</div>
</template>

<script>
import SpecialZone from '@/components/specialZone'
import marquee from '@/components/marquee'
import coupon from '@/components/coupon'

export default {
	name: 'Home',
	components: {
		SpecialZone,
		marquee,
		coupon
	},

	data() {
		return {
			notice: '',
			classifyList: [],
			bannerList: [],
			active: '',
			show: false
		};
	},

	created() {
		this.init()
	},

	methods: {
		init() {
			this.getClassify()
			this.getBanner()
			this.getNotice()
			this.$nextTick(() => {
				this.$refs.miaosha.getTitle()
				this.$refs.healthy.getTitle()
				this.$refs.recommend.getGoods()
			})
		},

		//获取分类
		getClassify () {
			this.$https.get(this.$api.category,{
				parentid: 0,
				centreId: this.$api.centreId
			})
			.then((res) =>{
				let list = this.makeTree(res.data)
				this.classifyList = list
			})
		},

		//处理分类数据
		makeTree(data){
			let tree = {}
			data.forEach(item => {
				if(item.enabled &&  (item.status === 1)){
					tree[item.id] = item
				}
			})
				
			data.forEach(item => {
				if (item.enabled && (item.status === 1) && (item.parentid > 0)) {
					let parent = tree[item.parentid]
					if (parent) {
						if (!parent.children) {
							parent.children = []
						}
						parent.children.push(item)
					}
				}
			})
				
			return data.filter(item => (item.parentid === 0) && ((item.enabled &&  (item.status === 1))))
		},

		//分类触摸
        mouseenter(index){
			if(this.classifyList[index].categoryList.length == 0) {
				this.active = ''
				this.show = false
			} else {
				this.active = index
				this.show = true
			}
        },

        mouseleave() {
			this.active = ''
            this.show = false
        },

		//轮播图
		getBanner() {
			this.$https.get(this.$api.banner,{
                type: 1,
                centerId: this.$api.centreId,
                top: 10
            })
            .then((res) =>{
                this.bannerList = res.data
            })
		},

		//公告
		getNotice() {
			this.$https.get(this.$api.notice,{
				centreId: this.$api.centreId || ''
            })
            .then((res) =>{
                if(res.data && res.data.rows.length > 0){
					this.notice = res.data.rows[0].content.replace(/[\r\n]/g,"")
				}
            })
		},

		toGoodList(name, categoryId) {
			this.$router.push({
				name: 'goodList',
				query: {
					name: encodeURIComponent(name),
					categoryId: categoryId
				}
			})
		}
	}
}
</script>
<style lang='scss' scoped>
	.classify-container{
		width: 256px;
		height: 340px;
		background-color: #F1F1F1;
		position: relative;
		.classify-list{
			width: 256px;
			height: 340px;
			padding: 20px 12px 20px;
			overflow-y: auto;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
		}
		div{
			width: 100%;
			flex-shrink: 0;
			margin-bottom: 12px;
			text-align: center;
			&:last-child{
				margin-bottom: 0;
			}
			span{
				cursor: pointer;
				&:hover{
					color: var(--blue-color);
				}
			}
		}
		.classify-children{
			position: absolute;
			top: 0;
			left: 100%;
			width: 700px;
			height: 340px;
			padding: 23px;
			background-color: #fff;
			box-shadow: 0 0 8px rgba(0, 0, 0, .4);
			z-index: 99;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			.children-item{
				width: auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0 20px 20px 0;
				img{
					width: 60px;
					height: 60px;
					border-radius: 10px;
				}
				cursor: pointer;
			}
		}
	}
	.banner-container{
		width: 700px;
		height: 340px;
		img{	
			width: 700px;
			height: 340px;
		}
	}
	.sidebar-container{
		width: 246px;
		height: 340px;
		.sidebar-qrcode-box{
			height: 190px;
			padding-top: 15px;
			background: linear-gradient(180deg, rgba(12, 56, 144, 0.7) 1%, rgba(12, 56, 144, 0) 100%);
			.qrcode{
				width: 100px;
				height: 100px;
			}
			.qrcode-tips{
				width: 130px;
				height: 40px;
				margin-top: 15px;
				background: url(../../assets/images/sprite.png) no-repeat;
				background-position: 0 -112px;
			}
		}
		.merquee-container{
			height: 30px;
			padding: 0 10px;
			margin-top: 10px;
		}
		.meta-container{
			padding: 0 10px;
			margin-top: 20px;
			.order,.coupon{
				width: 30px;
				height: 28px;
				background: url(../../assets/images/sprite.png) no-repeat;
				background-position: -85px -74px;
			}
			.coupon{
				background-position: -48px -74px;
			}
		}
	}
</style>
